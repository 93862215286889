import * as React from "react"
import { Link } from "gatsby"
import { Waypoint } from 'react-waypoint'

import Layout from "../components/layout"
import Seo from "../components/seo"

import QuoteDesktop from "../images/quote-pmr-treatment-journey-desktop.png"
import QuoteMobile from "../images/quote-pmr-treatment-journey-mobile.png"
import Gutter from "../images/gutter-pmr-treatment-journey.png"
import LockUpHexShapes from "../images/lockup-hex-shapes-pmr-treatment-journey.svg"

import IconPhysicalExam from "../images/icon-physical-exam.svg"
import IconBloodTest from "../images/icon-blood-test.svg"
import IconImagingTest from "../images/icon-imaging-test.svg"
import IconHeartDisease from "../images/icon-heart-disease.svg"
import IconOsteoporosis from "../images/icon-osteoporosis.svg"
import IconDiabetes from "../images/icon-diabetes.svg"
import IconNext from "../images/icon-next.png"
import IconCallOutSpeechBubbles from "../images/icon-callout-speech-bubbles.svg"

import HexPmrExperience from "../images/hex-pmr-experience.png"
import HexIconsDailyActivitiesDesktop from "../images/hex-icons-daily-activities-desktop.png"
import HexIconsDailyActivitiesMobile from "../images/hex-icons-daily-activities-mobile.png"

import HeroImage from "../images/hero-pmr-treatment-journey.png"

const PmrTreatmentJourney = () => {

  const handleVerticalLineAnimations = () => {
    const target = document.querySelectorAll('.vertical-line-dark-red');
    target.forEach((element) => {
      element.classList.add('animate');
    });
  }

  return (
    <Layout>
      <Seo 
        title="PMR Treatment Journey"
        desc="Learn about the PMR treatment journey."
        ogtitle="PMR Treatment Journey | PMR and You Website"
        ogdesc="Learn about the PMR treatment journey."
      />

      <div style={{ position: 'relative' }}>
        <div className="main-hero pmr-treatment">
            <h1 className="columns headline-hero">PMR Treatment Journey</h1>
        </div>

        <div id="pmr-treatment-hero-image" className="text-right"><img src={HeroImage} alt="woman smiling close-up" id="pmr-treatment-hero-image-img" /></div>
      </div>

      <div className="content-container pmr-treatment-content">
        <div>
          <p className="headline-body">How is PMR diagnosed?</p>
          <p>If you haven’t been able to get relief from your pain, talk to your primary care doctor. They can help find out if your pain is caused by PMR. Your primary care doctor may also refer you to a rheumatologist, who can diagnose and treat your PMR. A rheumatologist is a doctor who specializes in treating inflammatory rheumatic diseases.</p>
          <p>PMR can be hard to diagnose because it causes symptoms that are similar to other conditions.</p>
        </div>

        <div className="row mt-2 md_mt-2 mb-3 md_mb-3">
          <div className="columns small-12 medium-12 large-6 align-self-middle text-center large-text-left mb-2 md_mb-0">
            <div className="mt-1 mb-2 hide-for-large"><img src={QuoteMobile} alt="I assumed I had hurt myself at the gym, so I tried the chiropractor first. --a real person with PMR" /></div>
            <div className="md_mb-2 show-for-large"><img src={QuoteDesktop} alt="I assumed I had hurt myself at the gym, so I tried the chiropractor first. --a real person with PMR" /></div>
          </div>
          <div className="columns small-12 medium-12 large-6 align-self-middle">
            <div className="callout red">
              <p><img src={IconCallOutSpeechBubbles} alt="message bubbles icon" /></p>
              <p>Stay positive</p>
              <p className="font-weight-normal">Talk with your doctors to get the answers you need while they confirm your PMR diagnosis.</p>
            </div>
          </div>
        </div>

        <div><p className="headline-subhead">To find out if PMR is the source of your pain, your rheumatologist may do certain tests, including:</p></div>

        <div className="callout light-gray row mt-2 mb-2 md_mt-4 md_mb-4" style={{ maxWidth: '1070px', margin: '0 auto' }}>
          <div className="columns small-12 medium-12 large-3">
            <p style={{ height: '105px', minHeight: '105px', maxHeight: '105px' }}><img src={IconPhysicalExam} alt="man moving icon"/></p>
            <p className="color-dark-red font-weight-semi-bold">Physical exam:</p>
            <p className="font-weight-normal">Looks at how you sit, stand, walk, and move <span className="nowrap">your arms</span></p>
          </div>
          <div className="columns small-12 medium-12 large-1 align-self-middle">
            <div className="vertical-line-dark-red h150px left show-for-large"></div>
            <div className="horizontal-line-dark-red hide-for-large mt-2 mb-3"></div>
          </div>
          <div className="columns small-12 medium-12 large-4">
            <p style={{ height: '105px', minHeight: '105px', maxHeight: '105px' }}><img src={IconBloodTest} alt="syringe icon" style={{ position: 'relative', top: '25px' }}/></p>
            <p className="color-dark-red font-weight-semi-bold">Blood test:</p>
            <p className="font-weight-normal">Looks for inflammation in <span className="nowrap">your body</span></p>
          </div>
          <div className="columns small-12 medium-12 large-1 align-self-middle">
            <div className="vertical-line-dark-red h150px left show-for-large"></div>
            <div className="horizontal-line-dark-red hide-for-large mt-2 mb-3"></div>
          </div>
          <div className="columns small-12 medium-12 large-3">
            <p style={{ height: '105px', minHeight: '105px', maxHeight: '105px' }}><img src={IconImagingTest} alt="magnifying glass icon" style={{ position: 'relative', top: '25px' }}/></p>
            <p className="color-dark-red font-weight-semi-bold">Imaging tests:</p>
            <p className="font-weight-normal">Look at abnormal finding in your shoulders <span className="nowrap">and hips</span></p>
          </div>
        </div>

        <Waypoint onEnter={handleVerticalLineAnimations}/>

      </div>


      <div className="row mb-3">
        <div className="columns small-12 medium-12 large-8" style={{ padding: '0 80px' }}>It might be overwhelming to find out that you have PMR. The sooner you have a diagnosis, the sooner your doctor can figure out a treatment plan that will help you feel better.</div>
        <div className="columns small-12 medium-12 large-4 text-right medium-text-right"><img src={LockUpHexShapes} alt="septagon icon"/></div>
      </div>


      <div className="content-container">
        <div>
          <p className="headline-body">How is PMR treated?</p>
          <p><span className="font-weight-semi-bold">It’s important for you to take an active role in your treatment plan.</span> Treatment is more successful when you work with your doctor. Be sure to ask your doctor questions and let them know if you have any concerns.</p>
        </div>

        <div className="row md_mt-2 lg_mt-3">
          <div className="columns small-12 medium-12 large-7" style={{ paddingLeft: '0', paddingRight: '0'}}>
            <p className="headline-subhead">Every person's PMR experience is different</p>
            <div className="callout light-gray">
              <ol>
                <li className="one">Talk to your healthcare team about your symptoms</li>
                <li className="two">Make sure you share your health history</li>
                <li className="three">Create a treatment plan that works for you</li>
              </ol>
            </div>
          </div>
          <div className="columns small-12 medium-12 large-5 text-right mt-4 mb-4" style={{ paddingLeft: '0', paddingRight: '0'}}>
            <img src={HexPmrExperience} alt="woman smiling outside"/>
          </div>
        </div>

        <div className="mb-2 md_mb-3">
          <p className="headline-body">How do steroids work?</p>
          <p>Standard of care for PMR begins with glucocorticoids (GCs). Low-to-moderate dosages of GCs have quickly helped PMR symptoms.</p>
          <p>Steroids may provide relief by reducing the inflammation that causes your pain and stiffness. Your doctor will slowly lower your dose of steroids over time as you start to feel better.</p>
          <p>The long-term use of GCs can be associated with the following:</p>

          <div className="row mt-1 md_mt-3 mb-2 md_mb-2">
            <div className="columns text-center small-12 medium-12 large-4">
              <div>
                <p><img src={IconHeartDisease} alt="heart icon"/></p>
                <p className="color-dark-red">Heart disease</p>
              </div>
            </div>
            <div className="columns text-center small-12 medium-12 large-4 mt-3 mb-3">
              <div>
                <p><img src={IconOsteoporosis} alt="bone icon"/></p>
                <p className="color-dark-red">Osteoporosis<br/>(bone density disease)</p>
              </div>
            </div>
            <div className="columns text-center small-12 medium-12 large-4">
              <div>
                <p><img src={IconDiabetes} alt="vial and syringe icon"/></p>
                <p className="color-dark-red">Diabetes</p>
              </div>
            </div>
          </div>
          
          <p>For some people, symptoms can return if your steroid treatment dose is too low or you stop taking them. The goal of a steroid treatment plan is to relieve your symptoms and then to slowly lower the dose without causing pain or stiffness to return.</p>
        </div>

        <div className="mb-2 md_mb-4">
          <p className="headline-subhead">The impact of untreated symptoms</p>
          <p>PMR can impact people's mobility and independence. People may go from living very active lives to more limited ones, even having difficulty getting out of bed.</p>
          <p>People living with PMR may struggle to perform daily activities such as:</p>
          <div className="callout light-gray">
            <img src={HexIconsDailyActivitiesDesktop} alt="Getting dressed, bathing, combing their hair, getting out of bed, getting in and out of a car" className="show-for-large" />
            <img src={HexIconsDailyActivitiesMobile} alt="Getting dressed, bathing, combing their hair, getting out of bed, getting in and out of a car" className="hide-for-large" />
          </div>
        </div>


        <Link to="/support-and-resources/" className="callout-link">
          <div className="callout next-page pmr-treatment-journey row" style={{ margin: '0 0 0 auto' }}>
            <div className="columns small-12 medium-8 large-9 align-self-middle mb-2 md_mb-0">Find the support you need</div>
            <div className="columns small-12 medium-4 large-3 align-self-middle"><img src={IconNext} alt="arrow icon" className="icon-next-arrow icon-next-arrow-animation" /></div>
          </div>
        </Link>
      </div>

      <div className="mt-4"><img src={Gutter} alt="book and shapes icon" style={{ width: '80%', maxWidth: '796px' }}/></div>
      <br/>

    </Layout>
  )
}
export default PmrTreatmentJourney
